import logoInverse from "components/assets/utils/images/logo-inverse.png";
import Icon from "components/widgets/Icon";
import { switchLanguage } from "components/widgets/LanguageSwitch";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { breakPoints } from "utils/constants";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useLogout, useUser } from "utils/hooks/auth";
import useWindowDimensions from "utils/hooks/window";
import cx from "classnames";
import { MainNav } from "./navItems";

function AppSidebar(props) {
  const { t, i18n } = useTranslation();
  const { data: user } = useUser();
  const logout = useLogout({});
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    if (width < breakPoints.xxl) setCollapsed(true);
  }, [width]);

  useEffect(() => {
    if (toggled && !location.state?.sidebar) setToggled(false);
  }, [location]);

  function linkClass({ isActive }) {
    if (isActive) return "active";
    return "";
  }

  return (
    <>
      <Sidebar
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
        className="sidebar vh-100"
        breakPoint="sm"
        collapsed={collapsed}
        width="300px"
      >
        <div className="px-2 pb-5 pt-4 sidebar-logo d-flex position-relative justify-content-between">
          <img src={logoInverse} width={60} height={14} />
          <Button
            onClick={() => setCollapsed(true)}
            size="sm"
            className={cx("me-4 bg-light bg-opacity-10", {
              "d-none": collapsed,
            })}
          >
            <i className="bi bi-arrow-left"></i>
          </Button>
        </div>
        <Menu className="p-2">
          {MainNav(user?.role_id).map((item) => {
            if (item.content) {
              return (
                <SubMenu
                  key={item.label}
                  label={item.label}
                  icon={<Icon name={item.icon} />}
                >
                  {item.content.map((subItem) => (
                    <MenuItem
                      key={subItem.label}
                      to={subItem.to}
                      component={<NavLink to={subItem.to} />}
                    >
                      {subItem.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              );
            }
            return (
              <MenuItem
                key={item.label}
                icon={<Icon name={item.icon} />}
                component={<NavLink to={item.to} />}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>

        <Menu className="sidebar-bottom flex-grow-1 d-flex align-items-end p-2">
          <MenuItem
            icon={<Icon name="profile" />}
            component={<NavLink to="/users/profile" />}
          >
            {t("common:profile")}
          </MenuItem>
          {isRole(Role.USER, user) && (
            <MenuItem
              icon={<Icon name="student" />}
              component={<NavLink to="/students/parent" />}
            >
              {t("plans:students")}
            </MenuItem>
          )}
          <MenuItem
            icon={<Icon name="support" />}
            component={
              <a className="" target="_blank" href="https://rubapay.com" />
            }
          >
            {t("common:support")}
          </MenuItem>
          <MenuItem
            icon={<Icon name="settings" />}
            component={<a onClick={() => logout.mutate({})} href="#" />}
          >
            {t("common:logout")}
          </MenuItem>
          <MenuItem
            icon={<Icon name="language" />}
            component={<a onClick={switchLanguage} href="#" />}
          >
            {i18n.language == "en" ? "عربي" : "English"}
          </MenuItem>
          {/* version: 2.0.0 */}
        </Menu>
      </Sidebar>
      <Nav
        style={{ zIndex: 1030 }}
        className="mobile-navbar d-flex d-sm-none p-3 position-fixed bottom-0 start-0 end-0 w-100 justify-content-around"
        variant="pills"
        activeKey="1"
        onSelect={() => { }}
      >
        <Nav.Item>
          <NavLink className={linkClass} to="/plans" title="Item">
            <Icon name="document" />
            <span className="text">{t("plans:plans")}</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className={linkClass} to="/users/profile">
            <Icon name="profile" />
            <span className="text">{t("common:profile")}</span>
          </NavLink>
        </Nav.Item>
        {isRole(Role.USER, user) && (
          <Nav.Item>
            <NavLink className={linkClass} to="/installments">
              <Icon name="dollar" />
              <span className="text">{t("plans:payments")}</span>
            </NavLink>
          </Nav.Item>
        )}
        <Nav.Item>
          <NavLink
            state={{ sidebar: true }}
            onClick={() => setToggled((prev) => !prev)}
            to="#"
            className={false}
          >
            <Icon name="more" />
          </NavLink>
        </Nav.Item>

        <div
          className={cx("more", {
            "d-none": !toggled,
          })}
        >
          <Row>
            {MainNav(user?.role_id).map((item) => {
              if (item.content) {
                return <Fragment key={item.label}>
                  <Col
                    lg={12}
                    className="py-2 d-flex align-items-center justify-content-between hover:bg-gray-700/50 rounded cursor-pointer"
                    onClick={() => setOpenItems((prev) =>
                      prev.includes(item.label)
                        ? prev.filter(label => label !== item.label)
                        : [...prev, item.label]
                    )}
                  >
                    <div className="d-flex align-items-center">
                      <Icon name={item.icon} />
                      <span className="ms-2 text-white fbs-3">{item.label}</span>
                    </div>
                    <i className={`bi bi-chevron-${openItems.includes(item.label) ? 'up' : 'down'} text-white`}></i>
                  </Col>
                  {openItems.includes(item.label) &&
                    <div className="px-3">
                      {item.content.map((subItem) => (
                        <Col key={subItem.label} lg={12} className="py-2 ">
                          <NavLink
                            state={{ sidebar: true }}
                            key={subItem.to}
                            onClick={() => setToggled((prev) => !prev)}
                            to={subItem.to}
                            className={false}
                          >
                            <span className="ms-5 text-white fbs-3">{subItem.label}</span>
                          </NavLink>
                        </Col>
                      ))}
                    </div>
                  }
                </Fragment>
              }
              return (
                <Col key={item.label} lg={12} className="py-2">
                  <NavLink
                    state={{ sidebar: true }}
                    key={item.to}
                    onClick={() => setToggled((prev) => !prev)}
                    to={item.to}
                    className={false}
                  >
                    <Icon name={item.icon} />
                    <span className="ms-2 text-white fbs-3">{item.label}</span>
                  </NavLink>
                </Col>
              );
            })}
            <Col lg={12} className="py-2 pt-4">
              <a onClick={() => logout.mutate({})}>
                <Icon name="settings" />
                <span className="ms-2 text-white fbs-3">{t("common:logout")}</span>
              </a>
            </Col>
            <Col lg={12} className="py-2">
              <a className="" target="_blank" href="https://rubapay.com">
                <Icon name="support" />
                <span className="ms-2 text-white fbs-3">{t("common:support")}</span>
              </a>
            </Col>
            <Col lg={12} className="py-2">
              <NavLink className={linkClass} onClick={switchLanguage} to="/">
                <Icon name="language" />
                <span className="ms-2 text-white fbs-3">
                  {i18n.language == "en" ? "عربي" : "English"}
                </span>
              </NavLink>
            </Col>
          </Row >
        </div>
      </Nav>
    </>
  );
}

export default AppSidebar;
