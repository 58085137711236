import client from "../api";

const base = "dashboard-reserves";

export type AdminReservePaymentsSummaryResponse = {
    summary: {
        overdue: number;
        upcoming: number;
        remaining: number;
    }
    listOfOverdue: {
        id: string;
        amount: number;
        scheduled_date: string;
        status: string;
        user_name: string;
        plan_id: string;
    }[];
}
export type AdminReservePaymentsSummaryParams = {
    school_id: string;
}
export function adminReservePaymentsSummary(queryParamsObject: AdminReservePaymentsSummaryParams): Promise<AdminReservePaymentsSummaryResponse> {
    return client.get(`${base}/admin-reserve-payments-summary`, { params: queryParamsObject });
}



const dashboardReserve = {
    adminReservePaymentsSummary
}

export default dashboardReserve;