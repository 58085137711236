import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from ".";
import DashboardReserves from "./AdminWidgets/Reserves";
import DashboardPaynow from "./AdminWidgets/Paynow";

const DashboardRoutes = () => {
  return (
    <Routes >
      <Route path="/reserves" element={<DashboardReserves />} />
      <Route path="/paynow" element={<DashboardPaynow />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
};

export default DashboardRoutes;
