import { useQuery } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import Icon from "components/widgets/Icon";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import reserves from "utils/api/reserves";

type Props = {};

const ReserveList = (props: Props) => {
  const { data } = useQuery(["reserves"], reserves.list, {
    placeholderData: []
  });
  const { t } = useTranslation(["common", "plans", "schools"]);
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("plans:name")}</th>
                  <th>{t(`common:category`)}</th>
                  <th>{t("common:number")}</th>
                  <th>{t("common:balance")}</th>
                  <RoleBasedRenderer
                    admin={
                      <th>{t("common:type")}</th>
                    }
                    fi={
                      <th>{t("common:clients")}</th>
                    }
                    school={
                      <th>{t("common:clients")}</th>
                    } />
                  <th>{t("plans:actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.entity?.name}</td>
                    <td>{`${t("common:"+item.category)}`}</td>
                    <td>{item.reserve_number}</td>
                    <td>
                      <Amount amount={item.balance}/>
                    </td>
                    <RoleBasedRenderer
                      admin={
                        <th className="text-capitalize">{item.type}</th>
                      }
                      fi={
                        <th>{item.meta?.clients}</th>
                      }
                      school={
                        <th>{item.meta?.clients}</th>
                      } />
                    <td>
                      <RoleBasedRenderer user={
                        <Link to={`/reserves/${item.id}`}>
                          <Icon name="openEye" />
                        </Link>}
                        school={
                          <Link to={`/reserves/${item.id}/clients`} >
                            <Icon name="openEye" />
                          </Link>}
                        fi={
                          <Link to={`/reserves/${item.id}/clients`} >
                            <Icon name="openEye" />
                          </Link>}
                        moderator={
                          <Link to={`/reserves/${item.id}/clients`}>
                            <Icon name="openEye" />
                          </Link>}
                        admin={
                          <Link to={`/reserves/${item.id}/clients`}>
                            <Icon name="openEye" />
                          </Link>} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReserveList;
