import { useQuery } from "@tanstack/react-query";
import MediumLineCard from "components/widgets/Dashboard/MediumLineCard";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import dashboard from "utils/api/dashboard";

type Props = {};

const AdminStatsWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { data, isFetching } = useQuery(
    ["dashboard", "admin-stats", queryParamsObject],
    () => dashboard.adminStats(queryParamsObject),
    {
      placeholderData: {
        generatedRevenue: {
          amount: 0,
          data: [],
          difference: 0,
        },
        contractLosses: {
          amount: 0,
          data: [],
          difference: 0,
        },
        transactions: {
          amount: 0,
          data: [],
          difference: 0,
        },
        fiCut: {
          amount: 0,
          data: [],
          difference: 0,
        },
      },
    }
  );

  const { t } = useTranslation(["plans"]);
  return (
    <>
      <Col lg={3} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:generated_revenue")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.generatedRevenue?.difference}
          data={data?.generatedRevenue?.data}
          text={
            <>
              {data?.generatedRevenue?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={3} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:contract_losses")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.contractLosses?.difference}
          data={data?.contractLosses?.data}
          text={
            <>
              {data?.contractLosses?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={3} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:fi_cut")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.fiCut?.difference}
          data={data?.fiCut?.data}
          text={
            <>
              {data?.fiCut?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={3} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:transactions")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.transactions?.difference}
          data={data?.transactions?.data}
          text={
            <>
              {data?.transactions?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
    </>
  );
};

export default AdminStatsWidget;
