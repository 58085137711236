import { useQuery } from "@tanstack/react-query";
import ReferralProgress from "components/widgets/Referral/Progress";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { plans } from "utils/api/plans";
import Role from "utils/enums/Role";
import { isOneOfRoles } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import PlanAndReserveStatuses from "../List/components/PlanAndReserveStatuses";
import AdminPlanNoteTaker from "./Actions/AdminActions/NoteTaker";
import PlanActionsDetails from "./ActionsDetails";
import BasicInfo from "./BasicInfo";
import PlanDocumentsDetails from "./DocumentsDetails";
import PlanExtraDetails from "./ExtraDetails";
import ParentInfo from "./ParentInfo";
import PaymentInfo from "./PaymentInfo";
import PlanGeneratorsDetails from "./PlanGeneratorsDetails";
import PlanLogsDetails from "./PlanLogsDetails";
import PlanStudentsDetails from "./StudentsDetails";
import { acceptedPlanStatuses } from "utils/enums/PlanStatus";
import ReserveStatus from "utils/enums/ReserveStatus";

const ViewPlan = (props: any) => {
  const { id } = useParams();
  const { data: user } = useUser();
  const { data: plan, isLoading } = useQuery(["plan", id], () =>
    plans.view(id)
  );

  if (isLoading) return <></>;

  const showReferral = !plan.paynow && isOneOfRoles([Role.ADMIN, Role.USER], user) && ([...acceptedPlanStatuses].includes(plan.status) || [ReserveStatus.ACTIVE, ReserveStatus.COMPLETE].includes(plan.reserve_status));

  return (
    <Container>
      <Row>
        <Col lg={12} className="d-flex mb-3 mb-lg-2">
          <Row>
            <Col sm="auto">
              <h5 className="text-dark fw-bold me-2">{plan.user?.name}</h5>
            </Col>
            <Col>
              <PlanAndReserveStatuses plan={plan} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="flex-wrap-reverse">
        <Col lg={8}>
          <BasicInfo plan={plan} dashboard={true} />
          <ParentInfo plan={plan} />
          <PlanExtraDetails plan={plan} />
          <PlanStudentsDetails plan={plan} />
          <PlanDocumentsDetails plan={plan} />
          <PlanGeneratorsDetails plan={plan} />
        </Col>
        <Col lg={4}>
          <PlanActionsDetails plan={plan} />

          <Row className="gy-4">
            {showReferral && (
              <Col lg={12}>
                <ReferralProgress plan={plan} />
              </Col>
            )}
            {isOneOfRoles([Role.ADMIN, Role.FI], user) && (
              <Col lg={12}>
                <PaymentInfo plan={plan} />
              </Col>
            )}
            {isOneOfRoles([Role.ADMIN, Role.MODERATOR], user) && (
              <Col lg={12}>
                <AdminPlanNoteTaker plan={plan} />
              </Col>
            )}
            {isOneOfRoles([Role.ADMIN, Role.MODERATOR], user) && (
              <Col lg={12}>
                <PlanLogsDetails plan={plan} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewPlan;
