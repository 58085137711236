import Role from "utils/enums/Role";
import i18n from "utils/i18n/config";

export const MainNav = (role) => {
  let baseItems = [

  ];

  if (role == Role.USER) {
    baseItems = [
      ...baseItems,
      {
        icon: "grid",
        label: i18n.t("dashboard"),
        to: "/dashboard",
      },
      {
        icon: "dollar",
        label: i18n.t("plans:payments"),
        to: "/installments",
      },
      {
        icon: "student",
        label: i18n.t("plans:students"),
        to: "/students/parent",
      },
    ];
  }

  if (role == Role.MODERATOR) {
    baseItems = [
      ...baseItems,
      {
        icon: "grid",
        label: i18n.t("dashboard"),
        to: "/dashboard",
      },
      {
        icon: "document",
        label: i18n.t("plans:plans"),
        to: "/plans",
      },
      {
        icon: "reserve",
        label: i18n.t("common:reserves"),
        to: "/reserves",
      },
    ];
  }

  if (role == Role.SCHOOL) {
    baseItems = [
      ...baseItems,
      {
        icon: "grid",
        label: i18n.t("dashboard"),
        to: "/dashboard",
      },
      {
        icon: "document",
        label: i18n.t("plans:plans"),
        to: "/plans",
      },
      {
        icon: "students",
        label: i18n.t("plans:students"),
        to: "/students",
      },
      {
        icon: "building",
        label: i18n.t("schools:school"),
        to: "/schools",
      },
      {
        icon: "reserve",
        label: i18n.t("common:reserves"),
        to: "/reserves",
      },
      {
        icon: "transactions",
        label: i18n.t("plans:transactions"),
        to: "/plans/transactions",
      },
      {
        icon: "bagPlus",
        label: i18n.t("addons:addons"),
        to: "/addons",
      },
    ];
  }

  // FI options to check their own data
  if (role == Role.FI) {
    baseItems = [
      ...baseItems,
      {
        icon: "grid",
        label: i18n.t("dashboard"),
        to: "/dashboard",
      },
      {
        icon: "document",
        label: i18n.t("plans:plans"),
        to: "/plans",
      },
      {
        icon: "reserve",
        label: i18n.t("plans:payments"),
        to: "/reserves",
      },
      {
        icon: "transactions",
        label: i18n.t("plans:transactions"),
        to: "/plans/transactions",
      },
    ];
  }

  if (role == Role.ADMIN) {
    baseItems = [
      ...baseItems,
      {
        icon: "grid",
        label: i18n.t("dashboard"),
        content: [
          {
            label: "Overview",
            to: "/dashboard",
          },
          {
            label: "Reserves",
            to: "/dashboard/reserves",
          },
          {
            label: "Paynow",
            to: "/dashboard/paynow",
          },
        ],
      },
      {
        icon: "document",
        label: i18n.t("plans:plans"),
        to: "/plans",
      },
      {
        icon: "building",
        label: i18n.t("common:educational_institutions"),
        to: "/schools",
      },
      {
        icon: "document",
        label: i18n.t("schools:grades"),
        to: "/grades",
      },
      {
        icon: "bank",
        label: i18n.t("common:financial_institutions"),
        to: "/fis",
      },
      {
        icon: "transactions",
        label: i18n.t("plans:transactions"),
        to: "/plans/transactions",
      },
      {
        icon: "reserve",
        label: i18n.t("common:reserves"),
        to: "/reserves",
      },
      {
        icon: "users",
        label: i18n.t("common:users"),
        to: "/users",
      },
      {
        icon: "documentHeart",
        label: i18n.t("common:interests"),
        to: "/interests",
      },
      {
        icon: "bagPlus",
        label: i18n.t("addons:addons"),
        to: "/addons",
      },
      {
        icon: "openBook",
        label: i18n.t("common:logs"),
        to: "/logs",
      },
      {
        icon: "document",
        label: i18n.t("common:chatbot"),
        content: [
          {
            label: "Overview",
            to: "/chatbot",
          },
          {
            label: "History",
            to: "/chatbot/history",
          },
          {
            label: "Contacts",
            to: "/chatbot/contacts",
          },
        ],
      },
      {
        icon: "settings",
        label: i18n.t("common:settings"),
        to: "/settings",
      },
    ];
  }

  return baseItems;
};
