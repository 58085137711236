import { useMutation, useQuery } from "@tanstack/react-query";
import cx from "classnames";
import hesabeLogo from "components/assets/utils/images/payments/methods/hesabe.png";
import Icon from "components/widgets/Icon";
import TransactionTypeRenderer from "components/widgets/TransactionTypeRenderer";
import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import reserves from "utils/api/reserves";
import { transactions } from "utils/api/transactions";
import TransactionType from "utils/enums/TransactionType";
import { arabicValue, paymentIsDone } from "utils/functions/utilities";
import { usePaginate } from "utils/hooks/paginate";

type Props = {
  open: boolean;
  setOpen: any;
  overDue?: boolean;
  planId?: number;
};

const PaymentModal = (props: Props) => {
  const { open, setOpen, planId } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["plans", "common"]);
  const { data: serviceFee } = useQuery(["service-fee"], reserves.serviceFee);
  const { data: ws } = usePaginate({
    key: "transactions",
    api: transactions.list,
    options: {
      select: ({ data }: any) => {
        const pending = data.filter((item: any) => !paymentIsDone(item))
          .filter((item: any) => planId ? item.plan_id == planId : true);
        return pending;
      },
    },
  });

  const paymentLink = useMutation((transactionIds: any[]) =>
    reserves.rechargeLink(transactionIds)
  );

  function serviceFeeCalculator(type: string) {
    if (type == TransactionType.RECHARGE) return serviceFee;
    return 0;
  }

  function submit(values: any, formikBag: any) {
    if (values.step === 0) {
      formikBag.setFieldValue("step", 1);
      return;
    }
    setLoading(true);
    paymentLink.mutate(values.transactionsIds, {
      onSuccess: (data: any) => {
        window.location.replace(
          data?.link
        );
      },
      onError: (data: any) => {
        setLoading(false);
      },
    });
  }

  function shouldBeDisabled(values: any, item: any) {
    return false;
    if (!values.reserveTransactionId) {
      return false
    }
    return item.reserveTransaction?.id != values.reserveTransactionId
  }

  function handleChange(e: any, props: any, item: any) {
    if (e.target.checked) {
      props.setFieldValue("reserveTransactionId", item.reserveTransaction?.id)
      props.setFieldValue("transactionsIds", [...props.values.transactionsIds, item.id])
    } else {
      const newTransactionsIds = props.values.transactionsIds.filter((id: any) => id != item.id)
      props.setFieldValue("transactionsIds", newTransactionsIds)
      if (newTransactionsIds?.length == 0 || !newTransactionsIds) {
        props.setFieldValue("reserveTransactionId", undefined)
      }
    }
  }


  return (
    <>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="fs-6 fw-bold"
            id="contained-modal-title-vcenter"
          >
            {t("common:select_installments_to_pay")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              step: 0,
              type: "",
              transactionsIds: [],
              paymentMethod: "hesabe",
            }}
            onSubmit={submit}
          >
            {(props: any) => {
              const totalPayments = props.values.transactionsIds.reduce(
                (acc: any, id: any) => {
                  const transaction = ws?.find((item: any) => item.id === id);
                  if (transaction) {
                    return acc + transaction.amount;
                  }
                  return acc;
                },
                0
              );
              return (
                <FormikForm>
                  {props.values.step === 0 && (
                    <div className="overflow-auto" style={{ height: "50vh" }}>
                      {ws?.map((item: any) => {
                        const tOverdue =
                          DateTime.fromISO(item.scheduled_date) <
                          DateTime.now();
                        const selected = props.values.transactionsIds.includes(
                          item.id
                        );
                        return (
                          <Alert
                            key={item.id}
                            variant={selected ? "primary" : "white"}
                          >
                            <Form.Check
                              className="student-selector position-relative ps-2 ps-sm-4"
                              id={item.id}
                              type="checkbox"
                            >
                              <Form.Check.Input
                                disabled={shouldBeDisabled(props.values, item)}
                                onChange={(e: any) => {
                                  handleChange(e, props, item);
                                }}
                                type="checkbox"
                                name="transactionIds"
                                value={item.id}
                                className="d-none d-sm-block"
                                style={{
                                  top: "35%",
                                  left: "2.5%",
                                }}
                              />
                              <Form.Check.Label className="w-100">
                                <div className="d-flex w-100">
                                  <DateBox
                                    date={DateTime.fromISO(item.scheduled_date)}
                                    overDue={tOverdue}
                                  />
                                  <div className="mx-3 flex-grow-1 d-flex flex-column justify-content-center">
                                    <div className="d-flex flex-wrap justify-content-between">
                                      <p className="mb-1 text-dark opacity-75">
                                        {arabicValue(item.plan.school, "name")}
                                      </p>
                                      <TransactionTypeRenderer
                                        type={item.type}
                                      />
                                    </div>
                                    <p
                                      className={cx("mb-1 fw-bold fs-6", {
                                        "text-danger": tOverdue,
                                      })}
                                    >
                                      {item.amount}
                                    </p>
                                    <p className="text-dark opacity-75 fw-normal">
                                      {t("installment")}
                                      <span className="mx-1 fw-bold">
                                        {t("common:x_out_of_y", {
                                          x: item.order,
                                          y: item.total_per_plan,
                                        })}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Form.Check.Label>
                            </Form.Check>
                          </Alert>
                        );
                      })}
                    </div>
                  )}
                  {props.values.step === 1 && (
                    <Alert key="hesabe" variant="primary">
                      <Form.Check
                        className="student-selector position-relative"
                        id="hesabe"
                        type="checkbox"
                      >
                        <Form.Check.Input
                          type="checkbox"
                          name="paymentMethod"
                          value="hesabe"
                          onChange={() => { }}
                          checked={true}
                          style={{
                            top: "35%",
                            left: "2.5%",
                          }}
                        />
                        <Form.Check.Label>
                          <img src={hesabeLogo} alt="Hesabe" className="w-50" />
                        </Form.Check.Label>
                      </Form.Check>
                    </Alert>
                  )}
                  <div>
                    {/* <Alert
                      variant="light"
                      className="d-flex justify-content-between"
                    >
                      <div>
                        <p className="fs-7 text-dark fw-bold">
                          {t("total_amount")}
                        </p>
                        <p className="fbs-4 text-gray">
                          {t("common:reserve_service_fee", {
                            serviceFee: serviceFeeCalculator(props.values.type),
                          })}
                        </p>
                      </div>
                      <p className="fw-bold fs-5">
                        {serviceFeeCalculator(props.values.type) +
                          totalPayments}
                        <sup className="mx-1">{t("common:kd")}</sup>
                      </p>
                    </Alert> */}
                    <Button
                      disabled={loading}
                      type="submit"
                      size="lg"
                      className="w-100"
                    >
                      {loading ? (
                        t("common:redirecting")
                      ) : props.values.step === 0 ? (
                        <>
                          {t("common:proceed_to_pay", {
                            x:
                              serviceFeeCalculator(props.values.type) +
                              totalPayments,
                          })}
                          <sup className="mx-1">{t("common:kd")}</sup>
                        </>
                      ) : (
                        <>
                          {t("common:confirm_and_pay_x", {
                            x:
                              serviceFeeCalculator(props.values.type) +
                              totalPayments,
                          })}
                          <sup className="mx-1">{t("common:kd")}</sup>
                        </>
                      )}
                    </Button>
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

function DateBox({ date, overDue }: { date: DateTime; overDue?: boolean }) {
  return (
    <div
      className={cx(
        "bg-white  border rounded-1 p-2 px-3 d-flex flex-column justify-items-center align-items-center",
        {
          "border-danger": overDue,
        }
      )}
    >
      <p className="text-gray opacity-75">{date.monthShort}</p>
      <p className="fw-bold text-dark">{date.day}</p>
      <p className="text-gray opacity-75">{date.year}</p>
    </div>
  );
}

export default PaymentModal;
