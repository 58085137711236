import client from "../api";

const base = "dashboard-paynow";

export function adminTransactionVolume(): Promise<any> {
    // return client.get(`${base}/admin-reserve-payments-summary`, { params: queryParamsObject });
    return client.get(`${base}/admin-transaction-volume`, {});
}



const dashboardPaynow = {
    adminTransactionVolume
}

export default dashboardPaynow;