import { useQuery } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import { useState } from 'react';
import { Alert, Badge, Button, Col, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import dashboardReserve from "utils/api/dashboard-reserve";

type Props = {}

// Add an interface for the overdue payment data
interface OverduePayment {
    id: string;
    userName: string;
    amount: number;
    dueDate: string;
    daysOverdue: number;
    reservationId: string;
}

const AdminReservePaymentsSummaryWidget = (props: Props) => {
    const { t } = useTranslation(["common", "plans", "schools"]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = Object.fromEntries(queryParams.entries());
    const school_id = queryParamsObject.school_id;

    const { data } = useQuery(["admin-reserve-payments-summary", school_id], () => dashboardReserve.adminReservePaymentsSummary({
        school_id,
    }), {
        placeholderData: {
            summary: {
                overdue: 0,
                upcoming: 0,
                remaining: 0
            },
            listOfOverdue: []
        }
    });

    const [showOverdueModal, setShowOverdueModal] = useState(false);

    return (
        <>
            <Col lg={12}>
                <h6 className="text-dark">{t('payment_summary')}</h6>
            </Col>
            <Col lg={4}>
                <Alert variant="danger" onClick={() => setShowOverdueModal(true)}>
                    <div className="mb-3">{t("plans:overdue")}</div>
                    <div className="d-flex justify-content-between  fw-bold">
                        <p className="fs-4">
                            {data?.summary?.overdue?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                            })}
                            <sup className="mx-1">{t("common:kd")}</sup>
                        </p>
                    </div>
                </Alert>
            </Col>
            <Col lg="4">
                <Alert variant="white">
                    <div className="mb-3">{t("plans:upcoming_in_x_days", { days: 30 })}</div>
                    <div className="d-flex justify-content-between  fw-bold">
                        <p className="fs-4 text-dark">
                            {data?.summary?.upcoming?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                            })}
                            <sup className="mx-1">{t("common:kd")}</sup>
                        </p>
                    </div>
                </Alert>
            </Col>
            <Col lg="4">
                <Alert variant="white">
                    <div className="mb-3">{t("plans:remaining_installments")}</div>
                    <div className="d-flex justify-content-between  fw-bold">
                        <p className="fs-4 text-dark">
                            {data?.summary?.remaining?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                            })}
                            <sup className="mx-1">{t("common:kd")}</sup>
                        </p>
                    </div>
                </Alert>
            </Col>

            {/* Overdue Modal */}
            <Modal
                show={showOverdueModal}
                onHide={() => setShowOverdueModal(false)}
                centered
                size="lg"
            >
                <Modal.Header closeButton className="border-b">
                    <Modal.Title className="text-xl font-semibold">
                        {t("common:overdue_payments")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {/* Table view for larger screens */}
                    <div className="d-none d-md-block overflow-auto">
                        <Table hover size="sm" className="w-100">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-2 py-2 text-xs font-medium text-gray-500 uppercase">{t("plans:id")}</th>
                                    <th className="px-2 py-2 text-xs font-medium text-gray-500 uppercase">{t("plans:name")}</th>
                                    <th className="px-2 py-2 text-xs font-medium text-gray-500 uppercase">{t("plans:amount")}</th>
                                    <th className="px-2 py-2 text-xs font-medium text-gray-500 uppercase">{t("common:date")}</th>
                                    <th className="px-2 py-2 text-xs font-medium text-gray-500 uppercase">{t("plans:status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.listOfOverdue?.map((item) => (
                                    <tr key={item.id} className="border-b hover:bg-gray-50">
                                        <td className="px-2 py-2 text-xs">
                                            <Link to={`/plans/${item.plan_id}`} className="text-blue-600 hover:text-blue-800">
                                                {item.plan_id}
                                            </Link>
                                        </td>
                                        <td className="px-2 py-2 text-xs">{item.user_name}</td>
                                        <td className="px-2 py-2 text-xs">
                                            <Amount amount={item.amount} />
                                        </td>
                                        <td className="px-2 py-2 text-xs">
                                            {new Date(item.scheduled_date).toLocaleDateString()}
                                        </td>
                                        <td className="px-2 py-2 text-xs">
                                            <TransactionStatusRenderer {...item} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {/* Card view for mobile */}
                    <div className="d-md-none">
                        {data?.listOfOverdue?.map((item) => (
                            <div key={item.id} className="bg-white rounded shadow-sm p-3 border mb-3">
                                <div className="d-flex justify-content-between align-items-start mb-2">
                                    <Link 
                                        to={`/plans/${item.plan_id}`} 
                                        className="text-primary fw-medium"
                                    >
                                        #{item.plan_id}
                                    </Link>
                                    <TransactionStatusRenderer {...item} />
                                </div>
                                
                                <div className="small">
                                    <div className="d-flex justify-content-between mb-1">
                                        <span className="text-muted">{t("plans:name")}:</span>
                                        <span className="fw-medium">{item.user_name}</span>
                                    </div>
                                    
                                    <div className="d-flex justify-content-between mb-1">
                                        <span className="text-muted">{t("plans:amount")}:</span>
                                        <span className="fw-medium">
                                            <Amount amount={item.amount} />
                                        </span>
                                    </div>
                                    
                                    <div className="d-flex justify-content-between mb-1">
                                        <span className="text-muted">{t("common:date")}:</span>
                                        <span className="fw-medium">
                                            {new Date(item.scheduled_date).toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="text-center small text-muted mt-4">
                        {t("common:total_overdue_payments")}: {data?.listOfOverdue?.length}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-t">
                    <Button
                        variant="light"
                        onClick={() => setShowOverdueModal(false)}
                        className="mr-2"
                    >
                        {t("common:close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminReservePaymentsSummaryWidget