import { useQuery } from "@tanstack/react-query";
import MediumBarCard from "components/widgets/Dashboard/MediumBarCard";
import VerticalMediumPieCard from "components/widgets/Dashboard/VerticalMediumPieCard";
import Icon from "components/widgets/Icon";
import TablePages from "components/widgets/TablePages";
import { useState } from "react";
import { Breadcrumb, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import dashboard from "utils/api/dashboard";
import students from "utils/api/students";
import { usePaginate } from "utils/hooks/paginate";
import StudentsFilter from "./Filter";
import Amount from "components/widgets/Amount";

type Props = {

};

const StudentsList = (props: Props) => {
  const [filter, setFilter] = useState({});
  const { data, isFetching } = useQuery(
    ["onboarded-schools"],
    dashboard.studentsDemographics
  );
  const { t } = useTranslation(["common"]);

  const paginate = usePaginate({
    key: 'students',
    api: students.list,
    filter,
  });

  console.log(paginate?.data?.data);

  return (
    <Container>
      <Row className="row-gap-3">
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <Icon name="house" size={20} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("plans:students")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col lg={12}>
          <h4 className="text-dark fw-semibold">{t("plans:students")}</h4>
          <hr style={{ height: "1px" }} className="bg-light opacity-50" />
        </Col>

        <Col lg={4}>
          <VerticalMediumPieCard
            loading={isFetching}
            title={t("common:top_3_nationalities")}
            data={data?.nationalities}
          />
        </Col>
        <Col lg={4}>
          <VerticalMediumPieCard
            loading={isFetching}
            title={t("common:ruba_enrollee_gender_distribution")}
            data={data?.genders}
          />
        </Col>

        <Col lg={4}>
          <MediumBarCard
            loading={isFetching}
            title={t("common:top_grades")}
            data={data?.grades}
          />
        </Col>

        {/* filter input field */}
        <Col lg={12}>
          <Card className="mb-3">
            <Card.Header>
              <Row className="justify-content-between align-items-center">
                <Col lg="4">
                  <h5>{t("plans:students")}</h5>
                </Col>
                <Col lg="8">
                  <StudentsFilter filter={filter} setFilter={setFilter} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>{t("common:name")}</th>
                    <th>{t("plans:grade")}</th>
                    <th>{t("common:applicant")}</th>
                    <th>{t("plans:amount")}</th>
                    <th>{t("common:school_year")}</th>
                    <th>{t("common:actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {paginate?.data?.data?.map((student: any) => (
                    <tr>
                      <td>
                        {student.plans.length > 0 ? (
                          <Link
                            className="text-decoration-none text-dark"
                            to={`/plans/${student.plans[0]?.id}`}>
                            {student.name}
                          </Link>
                        ) : (
                          student.name
                        )}

                      </td>
                      <td>{student.grade?.name}</td>
                      <td>{student.plans[0]?.user?.name}</td>
                      <td>
                        <Amount amount={student.plans[0]?.amount} />
                      </td>
                      <td>{student.plans[0]?.current_year}</td>
                      <td>
                        <Link to={`/plans/${student.plans[0]?.id}`}>
                          <Icon name="openEye" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePages {...paginate} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};


export default StudentsList;
