import { useQuery } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import Icon from "components/widgets/Icon";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import TransactionTypeRenderer from "components/widgets/TransactionTypeRenderer";
import TransactionDetailsModal from "containers/Installments/List/DetailsModal";
import PaymentModal from "containers/Installments/List/PaymentModal";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, ButtonGroup, Card, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { transactions } from "utils/api/transactions";
import { breakPoints, transactionDoneStatuses } from "utils/constants";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";
import {
  arabicValue,
  installmentColor,
  isRole
} from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";

type Props = {
  reserve?: any;
};

const unsettledList = Object.values(TransactionStatus).filter((item: any) => !transactionDoneStatuses.includes(item));
const initialFilter = {
  status: unsettledList,
  students: []
};


const UnsettledList = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const [filter, setFilter] = useState<any>(
    id ? {
      ...initialFilter,
      reserve: id,
    } :
      initialFilter
  );
  const { data: user } = useUser();
  const { width } = useWindowDimensions();
  const { t } = useTranslation(["common", "plans", "schools"]);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [planId, setPlanId] = useState<any>(null);


  const { data: filterables } = useQuery(["transactions-filterables"], transactions.filterables, {
    placeholderData: {
      students: [],
    },
  });

  const { data } = usePaginate({
    key: "transactions",
    api: transactions.list,
    filter,
    options: {
      placeholderData: [],
    },
  });

  return (
    <>
      <PaymentModal planId={planId} open={open} setOpen={setOpen} />
      <TransactionDetailsModal
        open={openDetails}
        setOpen={setOpenDetails}
        planId={planId}
        handlePaynowClick={() => setOpen(true)}
      />
      {width > breakPoints.md ?
        <Card className="main-card mb-3">
          <RoleBasedRenderer user={
            <Card.Header className="d-flex justify-content-between align-items-center pb-3 pt-1">
              <h6 className="text-dark fs-6 fw-bold m-0">{t("upcoming_payments")}</h6>
              <div className="d-flex">
                <ButtonGroup size="sm" className="mx-3">
                  <Button
                    className="text-dark px-3 border"
                    variant={filter?.students?.length == 0 ? "light" : "outline"}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        students: [],
                      })
                    }}
                  >
                    {t('common:all_students')}
                  </Button>
                  {filterables?.students?.map((student: any) => <Button
                    key={student.id}
                    className="text-dark px-3 border"
                    variant={filter?.students?.includes(student.id) ? "light" : "outline"}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        students: [student.id]
                      })
                    }}
                  >
                    {student.name}
                  </Button>
                  )}
                </ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => setOpen(true)}
                >
                  {t('plans:pay_now')}!
                </Button>
              </div>
            </Card.Header>}
          />
          <Card.Body>
            <Table className="mb-0">
              <thead>
                <tr>
                  {isRole(Role.USER, user) ? (
                    <>
                      <th>{t("common:scheduled_date")}</th>
                      <th>{t("common:transfer_date")}</th>
                    </>
                  ) : (
                    <>
                      <th>{t("plans:plan_id")}</th>
                      <th>{t("common:scheduled_date")}</th>
                      <th>{t("common:transfer_date")}</th>
                    </>
                  )}
                  <th>{t("plans:school")}</th>
                  <th>{t("plans:amount")}</th>
                  <th>{t("plans:installments")}</th>
                  {!isRole(Role.FI, user) && <th>{t("common:type")}</th>}
                  <th>{t("plans:status")}</th>
                  <RoleBasedRenderer user={
                    <th>
                      {t("plans:actions")}
                    </th>
                  } />
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((item: any) => (
                  <tr key={item.id}>
                    {isRole(Role.USER, user) ? (
                      <>
                        <td>
                          {DateTime.fromISO(item.scheduled_date).toFormat("yyyy/MM/dd")}
                        </td>
                        <td>
                          {DateTime.fromISO(item.reserveTransaction?.scheduled_date).toFormat("yyyy/MM/dd")}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <Link
                            className="text-decoration-none"
                            to={`/plans/${item?.plan?.id}`}
                          >
                            {item?.plan?.public_id}
                          </Link>
                        </td>
                        <td>
                          {DateTime.fromISO(item.scheduled_date).toFormat("yyyy/MM/dd")}
                        </td>
                        <td>
                          {DateTime.fromISO(item.reserveTransaction?.scheduled_date).toFormat("yyyy/MM/dd")}
                        </td>
                      </>
                    )}

                    <td className="fw-bold">
                      {arabicValue(props.reserve?.school ?? item.plan.school, "name")}
                    </td>
                    <td className="fw-bold">
                      <Amount amount={item.amount} />
                    </td>
                    <td className="fw-bold">
                      {t("x_out_of_y", {
                        x: item.order,
                        y: item.total_per_plan,
                      })}
                    </td>
                    {!isRole(Role.FI, user) &&
                      <td>
                        <TransactionTypeRenderer type={item.type} />
                      </td>}
                    <td className="fw-bold">
                      <TransactionStatusRenderer
                        {...item}
                      />
                    </td>
                    <RoleBasedRenderer user={
                      <td>
                        <Button
                          onClick={() => {
                            setPlanId(item.plan_id)
                            setOpenDetails(true)
                          }}
                          variant="link"
                          className="p-0"
                        >
                          <Icon name="openEye" />
                        </Button>
                      </td>
                    } />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card> : <>
          {data?.data?.map((item: any) => (
            <Col
              onClick={() => isRole(Role.USER, user) && setOpen(true)}
              lg={12}
              className="border border-light rounded-2 mb-3 p-3"
            >
              <div className="d-flex justify-content-between">
                <h6 className={"fw-bold " + installmentColor(item)}>
                  <Amount amount={item.amount} />
                </h6>
                <p className="fbs-4 fw-semibold">
                  <TransactionStatusRenderer
                    {...item}
                  />
                </p>
              </div>
              <p className="fs-7 fw-semibold text-graycool mb-2">
                {/* {arabicValue(item.school, "name")} */}
              </p>
              <div className="d-flex justify-content-start mb-2">
                <div className="fbs-4">
                  {arabicValue(props.reserve?.school ?? item.plan.school, "name")}
                  {/* <TransactionTypeRenderer status={item.type} /> */}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <p className="fbs-4 fw-semibold text-dark">
                  {t("plans:installments")}
                </p>
                <p className="fbs-4 fw-semibold text-dark">{t("plans:plan_id")}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="fbs-4 text-gray">{t("x_out_of_y", {
                  x: item.order,
                  y: item.total_per_plan,
                })}</p>
                <p className="fbs-4 text-gray">{item?.plan?.public_id}</p>
              </div>
            </Col>
          ))}
        </>}</>
  );
};

export default UnsettledList;
