enum FileType {
  PERSONALID = "personal_id",
  PERSONALIDBACK = "personal_id_back",
  PASSPORT = "passport",
  SALARY_CERTIFICATE = "salary_certificate",
  AUTHORIZED_SIGNATORY = "authorized_signatory",
  BANK_STATEMENT = "bank_statement",
  PURCHASE_ORDER = "purchase_order",
  SIGNED_PURCHASE_ORDER = "signed_purchase_order",
  RECEIPT = "receipt",
  SIGNED_RECEIPT = "signed_receipt",
  LOGO = "logo",
  LICENSE = "license",
  COMMERCIAL_LICENSE = "commercial_license",
  LICENSE_SOCIAL_AFFAIRS = "license_social_affairs",
  BANK_LETTER = "bank_letter",
  DELEGATION_LETTER = "delegation_letter",
  QUOTATION = "quotation",
  SIGNED_QUOTATION = "signed_quotation",
  DELIVERY_NOTE = "delivery_note",
  CIVIL_ID_OF_AUTHORIZED_SIGNATORY = "civil_id_of_authorized_signatory",
  CINET = "cinet",
  CASH_UNDERSTAKING = "cash_undertaking",
  SIGNED_CASH_UNDERSTAKING = "signed_cash_undertaking",
  SIGNED_INVOICE = "signed_invoice",
  SIGNED_CREDIT_NOTE = "signed_credit_note",
  SIGNATURE = "signature",
  SIGNATURE_DELEGATION = "signature_delegation",
  MINISTRY_EDUCATION_LICENSE = "ministry_of_education_license",
  MANPOWER_ALLOWANCE="manpower_allowance_certificate",
  FINANCING_CONTRACT="financing_contract",
  SIGNED_RUBA_PURCHASE_ORDER="signed_ruba_purchase_order",
  SIGNED_RUBA_INVOICE="signed_ruba_invoice",
  SIGNED_RUBA_QUOTATION="signed_ruba_quotation",
  SERVICE_FEE_RECEIPT="service_fee_receipt",
  OTHER="other",
}

export default FileType;
