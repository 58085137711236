import AdminReservePaymentsSummaryWidget from 'containers/Dashboard/Widgets/AdminReservePaymentsSummary'
import AdminReserveStatsWidget from 'containers/Dashboard/Widgets/AdminReserveStats'
import AdminTotalReserve from 'containers/Dashboard/Widgets/AdminTotalReserve'
import WelcomeWidget from 'containers/Dashboard/Widgets/Welcome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useUser } from 'utils/hooks/auth'

type Props = {}

const DashboardReserves = (props: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <Row>
      <Col lg={12}>
        <h4 className="text-dark fw-bold">
          {t("reserve_overview")}
        </h4>
        <h5 className="fw-light">{t("reserve_overview_desc")}</h5>
      </Col>
      <AdminReservePaymentsSummaryWidget />
      <AdminTotalReserve />
      <AdminReserveStatsWidget />
    </Row>
  )
}

export default DashboardReserves