import WelcomeWidget from 'containers/Dashboard/Widgets/Welcome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useUser } from 'utils/hooks/auth'
import AdminPaynowTransactionVolume from './TransactionVolume'

type Props = {}

const DashboardPaynow = (props: Props) => {
    const { t } = useTranslation(["common"]);

    return (
        <Row>
            <Col lg={12}>
                <h4 className="text-dark fw-bold">
                    {t("paynow_overview")}
                </h4>
                <h5 className="fw-light">{t("paynow_overview_desc")}</h5>
            </Col>
            <AdminPaynowTransactionVolume />
        </Row>
    )
}

export default DashboardPaynow